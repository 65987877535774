
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'PageBlank',
})
export default class extends Vue {
  created() {
    const { redirect } = this.$route.params;
    redirect && this.$router.push({ name: redirect });
  }
}
